<template>
    <div>
  
      <!-- Media -->
      <b-media class="mb-2 d-flex align-items-center h-100">
        <template #aside>
        
          <!-- <b-avatar
            ref="previewEl"
            :src="BASE_URL+departmentData.image"
            :text="avatarText(departmentData.name)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="90px"
            rounded
          /> -->
        </template>
        <h4 class="mb-1 " style="text-transform: capitalize; font-weight: bold">
        {{ departmentData.name }} Projesi<br>
          
        </h4>
        <!-- <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline">Fotoğraf Yükle</span>
            <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
          >
            <span class="d-none d-sm-inline">Sil</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div> -->
      </b-media>
  
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
  
          <!-- Field: Username -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Bilgisayar Adı"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.computerName"
                disabled
                placeholder="Test-12C"
              />
            </b-form-group>
          </b-col> -->
  
          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Proje Adı"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="departmentData.name "
              />
            </b-form-group>
          </b-col>
          <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Proje bitiş tarihi"
            label-for="full-name"
          >
            <!-- <b-form-input
              id="full-name"
              v-model="departmentData.name "
            /> -->
            <flat-pickr v-model="departmentData.estimatedFinishDate" class="form-control " id="datePicker" :placeholder="dateText"
            :config="{ dateFormat: 'Y-m-d H:i' }" />
          </b-form-group>
        </b-col>
          <!-- Field: last Name -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Soyad"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.lastname "
              />
            </b-form-group>
          </b-col> -->
          <!-- Field: Email -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                type="email"
              />
            </b-form-group>
          </b-col> -->
  
          <!-- Field: Status -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Departman Rolu"
              label-for="user-status"
            >
              <v-select
                v-model="userData.isManager"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departmentRoles"
                :reduce="val => val.value"
                :clearable="false"
                disable
                input-id="user-status"
              />
            </b-form-group>
          </b-col> -->
  
          <!-- Field: Role -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Kullanıcı Rolu"
              label-for="user-role"
            >
           
              <v-select
                v-model="userData.isAdmin"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col> -->
    
    <!-- Field: Department -->
    <b-col
            cols="12"
            md="12"
          
           
          >
            <b-form-group
              label="Projeye Kullanıcı Ekle (Lütfen Kullanıcıları Seçin)"
              label-for="user-role"
              description="Tek seferde birden fazla kullanıcı ekleyebilirsiniz!"
            >
         <!-- {{ selectedAddProjectUsers }}  -->
            <!-- {{ projectUsers }}{{ isLoading }} -->
              <v-select
              v-if="!isLoading"
                v-model="selectedAddProjectUsers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="AddUsersList"
                item-value="value"
                item-text="label"
                
                :clearable="true"
                input-id="project-user-role"

                multiple
              />
            </b-form-group>
          </b-col> 
          <!-- Field: Email -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Şifre"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="password"
              />
            </b-form-group>
          </b-col> -->
  
           <!-- Field:tel  -->
           <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Telefon"
              label-for="company"
            >
              <b-form-input
                id="company"
                v-model="userData.phone"
                @input="validatePhoneNumber"
      pattern="[0-9]{11}"
      title="11 haneli bir numara giriniz"
      maxlength="11"
  
              />
            </b-form-group>
          </b-col> -->
  
        </b-row>
      </b-form>
  
      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      
        @click="projectUpdate"
      >
        Güncelle
      </b-button>
      <router-link :to="{ name: 'project-settings'}"> <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        İptal
      </b-button></router-link>
      <b-button
        variant="danger"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
      
        @click="projectDelete"
      >
      Projeyi Sil
      </b-button>
      <!-- Project USers TABLE -->
      <b-card
        no-body
        class="border mt-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Projeye Kayıtlı Kişiler</span>
          </b-card-title>
        </b-card-header>
        <!-- ss{{ projectUsers }} -->
        <b-table
          striped
          responsive
          class="mb-0"
          :items="projectUsers"
          :fields="fields"
         
        >
        <!-- <template #cell(departmentUserId)="data">
          {{ data.item.departmentUserId }} 
        </template> -->
      
          <template #cell(user)="data">
            {{ data.item.user.name }} {{ data.item.user.surname }}
          </template>
          <template #cell(departmentRole)="data">
          
            {{ data.item.user.generalRole == 'Admin' ? 'Admin': data.item.departmentRole == 'DepartmentManager' ?'Departman Yöneticisi' : 'Kullanıcı' }} 
          </template>
          <template #cell(phoneNo)="data">
          {{ data.item.user.phoneNo }} 
        </template> 
         <template #cell(email)="data">
          {{ data.item.user.email }} 
        </template>
          <template #cell(delete)="data" >
           <button v-if="data.item.isPermit" @click="projectUserDelete(data.item.projectDepartmentUserId)" type="button" class="btn btn-outline-primary">Kullanıcıyı Projeden Çıkar</button>
          </template>
        </b-table>
      </b-card>
  
     
    </div>
  </template>
  
  <script>
    import { Api } from '@/helpers/Api/apiConfig'
    import { BASE_URL } from '@/helpers/Url/BaseUrl'
  import {
    BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import vSelect from 'vue-select'
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
  import { ref } from '@vue/composition-api'
  import useUsersList from '../users-edit/useUsersList'
  import flatPickr from 'vue-flatpickr-component'
import moment from 'moment';
  export default {
   
    components: {
      BASE_URL,
      BButton,
      BMedia,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BTable,
      BCard,
      BCardHeader,
      BCardTitle,
      BFormCheckbox,
      vSelect,
      flatPickr
    },
    
    // props: {
    //   userData: {
    //     type: Object,
    //     required: true,
    //   },
    //  },
    // watch: {
    //   userData: {
    //     handler(val) {
    //       this.userData = val
    //     }
    //   }
    // },
    data() {
      return {
        departmentData:null,
        projectUsers:[],
        fields: [
          {
            key: 'user', label: 'Adı',
          },
          {
            key: 'departmentRole', label: ' Yetki',
          },
          { key: 'phoneNo', label: 'Tel', sortable: false },

           { key: 'email', label: 'Email', sortable: false },
           { key: 'delete', label: 'İşlemler', sortable: false },

        ],
        selectedAddProjectUsers: [],
        AddUsersList: [],
        //userDepartmentId:null,
      
      }
    },
    setup(props) {
    
      const { resolveUserRoleVariant } = useUsersList()
  
      const roleOptions = [
        { label: 'Admin', value: true },
        { label: 'Kullanıcı', value: false },
        // { label: 'Editor', value: 'editor' },
        // { label: 'Maintainer', value: 'maintainer' },
        // { label: 'Subscriber', value: 'subscriber' },
      ]
      const departmentList = [
        // { label: 'Admin', value: true },
        // { label: 'Kullanıcı', value: false },
        // { label: 'Editor', value: 'editor' },
        // { label: 'Maintainer', value: 'maintainer' },
        // { label: 'Subscriber', value: 'subscriber' },
      ]
      const departmentRoles = [
       // { label: 'Pending', value: 'pending' },
        { label: 'Yönetici', value: true },
        { label: 'Kullanıcı', value: false },
      ]
  
      const permissionsData = [
        {
          module: 'Admin',
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'Staff',
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: 'Author',
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: 'Contributor',
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'User',
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ]
  
      // ? Demo Purpose => Update image on click of update
      const refInputEl = ref(null)
      const previewEl = ref(null)
  
  const department = ref('sd');
  const isLoading = ref(false);
  const password = ref('');
  
  const departmentParentId=ref(null);
  
      const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
        // eslint-disable-next-line no-param-reassign
        props.userData.image = base64
      })
  
      return {
        departmentParentId,
        resolveUserRoleVariant,
        avatarText,
        roleOptions,
        departmentRoles,
        permissionsData,
        departmentList,
        department,
        isLoading,
        password,
        //  ? Demo - Update Image on click of update button
        refInputEl,
        previewEl,
        inputImageRenderer,
      }
    },
    // computed: {
    //   AddUsersList() {
    //     return this.projectUsers.map((item) => {
    //       return {
    //         ...item,
    //         label:item.user.name + ' ' + item.user.surname + ' - ' + item.departmentRole,
    //         value: item.departmentUserId
    //       }
    //     })
    //   }
    // },
    // watch: {
    //   AddUsersList (val) {
    //     this.AddUsersList=val
    //   }
    // },
    mounted() {
     this.getUserDepartment()
     this.getProjectAddUsers()
      //this.getDepartmentsDetail();
    },
    methods: {
      // rowClicked(item){
      //   // let userData=item.user
  
      //    console.log('event depart',item);
     
      //   //  this.$store.commit('settingsUser/setUserData',userData)
       
      //     this.$router.push({name:'user-edit',params:{id:item.user.userId}})
  
        
        
      // },
      // validatePhoneNumber() {
      //   // Telefon numarasının uzunluğunu kontrol et
      //   if (this.userData.phone.length > 11) {
      //     // Eğer 11 haneliden fazla ise, son girişi iptal et
      //     this.userData.phone = this.userData.phone.slice(0, 11);
      //   }
      // },
      async getProjectAddUsers() {
        this.isLoading = true;
        let projectId = this.$route.params.id;
        await Api("GET", "DepartmentUser/GetDepartmentUsersForAddProject?projectId="+projectId).then((res) => {
          this.AddUsersList = res.data.data.items.map((item) => {
          return {
            ...item,
            label:item.user.name + ' ' + item.user.surname + ' - ' + item.departmentUser.department.name + ' - ' + `${item.departmentUser.departmentRole == 'DepartmentManager' ?'Departman Yöneticisi' : 'Kullanıcı'}`,
            value: item.departmentUser.departmentUserId
          }
        });
         

         
          this.isLoading = false;
        });
      },
      async getUserDepartment() {
        this.isLoading = true;
        let projectId = this.$route.params.id;
        
        await Api("GET", "Project/getAllProjectDetail?projectId="+projectId ).then((res) => {
          console.log( 'ress project edit',res.data.data.items);
          this.departmentData = res.data.data.items[0]
          this.projectUsers = res.data.data.items[0].userList
         // this.departmentParentId = res.data.data.items.department.parentId
        //   let departmentId = res.data.data[0].departmentId;
        //   this.department = this.departmentList.find((item) => {
        //     return item.value === departmentId;
        //   }).value
         
        //   console.log( 'userreditdepartments',this.departments,'user',res.data.data[0].user);
        //   this.userDepartmentId = res.data.data[0].id
        //   this.userData == null?this.userData = res.data.data[0].user:null
        //   this.userData = {...this.userData,isManager:res.data.data[0].isManager }
        // console.log('77',this.userData);
          this.isLoading = false;
        });
      },
      async projectDelete(){
        // alert('Silme işlevi şuan aktif değil!')
        let projectId = this.$route.params.id
        await Api("DELETE", "Project/"+projectId ).then((res) => {
          alert(res.data.message=='OK' ?'Proje sistemden silindi.':'Hata! Proje sistemden silinemedi!.')
         this.$router.push({name:'project-settings'})
         console.log( 'project delete',res);
    
     
          this.isLoading = false;
        });
  
      },
      async projectUserDelete(projectDepartmentUserId){
        // alert('Silme işlevi şuan aktif değil!')
       // let projectId = this.$route.params.id
        await Api("DELETE", "ProjectDepartmentUser/"+projectDepartmentUserId ).then((res) => {
          alert(res.data.message=='OK' ?'Kullanıcı projeden silindi.':'Hata! Kullanıcı Projeden silinemedi!.')
        // this.$router.push({name:'project-settings'})
         console.log( 'project user deleted',res);
    
     
          this.isLoading = false;
        });
        this.getUserDepartment()
      },
      // async UpdateDepartmentUser() {
      //   //this.isLoading = true;
      //   let userId = Number(this.$route.params.id);
      //   await Api("PUT", "DepartmentUsers/"+this.userDepartmentId,{
          
          
      //     departmentId:this.department,
      //     userId:Number(userId),
      //     isManager:this.userData.isManager
        
        
      //   }).then((res) => {
      //    alert('Kullanıcı güncellendi.')
      //     console.log( 'DepartmentUsers update',res);
          
           
         
      //   });
      // },
     async projectUpdate(){
      //alert('yapım aşamasında')
        let projectId = this.$route.params.id
        const originalDate = moment(this.departmentData.estimatedFinishDate);
const newDate = moment(originalDate).startOf('day'); 
let finish = newDate.format('YYYY-MM-DDTHH:mm:ss');
        
        await Api("PUT", "Project/"+projectId,{
          //departmentId:this.departmentData.departmentId,
          name:this.departmentData.name,
          estimatedFinishDate:finish,
         
         
        
        
        
        }).then((res) => {
         alert(res.data.message=='OK' ?'Proje bilgileri güncellendi.':'Hata! Proje bilgileri güncellenemedi.')
        
         console.log( 'project update',res);
         
     
       });
  
      if(this.selectedAddProjectUsers.length > 0){
        this.projectAddUser()
      }
      //this.$router.push({name:'project-settings'})
      },
      async projectAddUser(){
      let projectNewUsers = this.selectedAddProjectUsers.map((item) => {
          return{
            projectId : this.$route.params.id,
            departmentUserId : item.departmentUser.departmentUserId
          }
        })
await Api ("POST", "ProjectDepartmentUser/AddMany",
  projectNewUsers
).then((res) => {
  alert(res.data.message=='OK' ?'Kişi ekleme işlemi başarılı.':'Hata! Proje eklemedi!.')
  this.selectedAddProjectUsers = []
  this.getUserDepartment()
})
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .vs__deselect{
color: white !important;
background: white !important;
padding: 5px !important;
border: 1px solid $primary !important;
  }
  </style>
  